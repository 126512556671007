import { mainnet, sepolia, type Chain } from 'viem/chains'
import type { IconName } from 'components/ui'

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL as string
const isDev = process.env.NEXT_PUBLIC_APP_ENV === 'development'

const rpcByChains = {
  [mainnet.id]: 'https://ethereum-rpc.publicnode.com',
  [sepolia.id]: 'https://ethereum-sepolia-rpc.publicnode.com',
} as const

export type ChainId = typeof mainnet.id | typeof sepolia.id

const appChainId = isDev ? sepolia.id : mainnet.id

export type ChainData = Chain & {
  icon: string
  iconName: IconName
  wrappedNative?: { name: string; symbol: string; decimals: number; address: Address }
}

const chainsMap: Record<ChainId, ChainData> = {
  [mainnet.id]: {
    ...mainnet,
    icon: '/images/icons/networks/ethereum.svg',
    iconName: 'networks/eth',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    wrappedNative: {
      name: 'WETH',
      symbol: 'WETH',
      decimals: 18,
      address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    },
    rpcUrls: {
      default: {
        http: [rpcByChains[mainnet.id], ...mainnet.rpcUrls.default.http],
      },
    },
  },
  [sepolia.id]: {
    ...sepolia,
    icon: '/images/icons/networks/ethereum.svg',
    iconName: 'networks/eth',
    nativeCurrency: { name: 'ETH Sepolia', symbol: 'ETH', decimals: 18 },
    wrappedNative: {
      name: 'WETH',
      symbol: 'WETH',
      decimals: 18,
      // https://github.com/SpoolFi/spool-v2-testnet-utils/blob/master/src/deploy/sepolia.constants.json
      address: '0x88B25A14DaE46c33ae3EEF2B45A5ec758D9a5Ec6',
    },
    rpcUrls: {
      default: {
        http: [rpcByChains[sepolia.id], ...sepolia.rpcUrls.default.http],
      },
    },
  },
}

export default {
  isDev,
  baseUrl,
  rpcByChains,
  appChainId,
  chainsMap,
}
