import clsx from 'clsx'
import { Dialog, DialogBackdrop, DialogPanel, CloseButton, Transition } from '@headlessui/react'
import { Icon } from 'components/ui'

import bgTileImage from 'public/images/bg-tile.png'

export type PlainModalProps = {
  className?: string
  withCloseButton?: boolean
  closeModal: (withOnClose?: boolean) => void
}

const style = { backgroundImage: `url(${bgTileImage.src})`, backgroundSize: '436px auto' }

const PlainModal: React.CFC<PlainModalProps> = (props) => {
  const { children, className, withCloseButton = true, closeModal } = props

  const panelClassName = clsx(
    className,
    'relative w-full max-w-lg rounded-5 bg-repeat p-2 lg:p-4',
    'transition duration-300 ease-out data-[closed]:translate-y-1/2 data-[closed]:scale-90 data-[closed]:opacity-0',
  )

  return (
    <Transition show static appear>
      <Dialog className="relative z-50" open onClose={() => closeModal(true)}>
        <DialogBackdrop
          transition
          className="fixed bottom-0 left-0 size-full bg-accent3-light/60 backdrop-blur-sm transition duration-300 ease-out data-[closed]:opacity-0"
        />
        <div className="fixed bottom-0 left-0 size-full overflow-y-auto overscroll-contain p-4">
          <div className="flex min-h-full items-center justify-center">
            <DialogPanel
              transition
              className={panelClassName}
              style={style}
              onClick={(event) => event.stopPropagation()}
            >
              <div className="relative rounded-5 bg-white px-4 py-6 shadow-y-4-10 lg:px-6">
                {withCloseButton && (
                  <CloseButton className="absolute right-4 top-4 hocus:opacity-70 lg:right-6 lg:top-6">
                    <Icon name="ui/close" className="size-5" />
                  </CloseButton>
                )}
                {children}
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default PlainModal
