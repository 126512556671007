'use client'

import React from 'react'
import dynamic, { type DynamicOptionsLoadingProps } from 'next/dynamic'
import { ModalsRenderer, registerModals } from '@locmod/modal'

import { Icon } from 'components/ui'
import SiweModal from 'compositions/modals/SiweModal/SiweModal'
import ErrorModal from 'compositions/modals/ErrorModal/ErrorModal'
import CommonModal from 'compositions/modals/CommonModal/CommonModal'
import SuccessModal from 'compositions/modals/SuccessModal/SuccessModal'

export const ModalSkeleton = (loadingProps?: DynamicOptionsLoadingProps) => {
  const { isLoading = true } = loadingProps || {}

  if (!isLoading) {
    return null
  }

  return (
    <div className="fixed inset-0 z-50 m-auto h-80 w-full max-w-lg">
      <div className="bone h-full w-full rounded-2" />
      <div className="absolute inset-0 flex items-center justify-center">
        <Icon name="ui/loading" className="size-16 animate-spin text-accent1" />
      </div>
    </div>
  )
}

const loading = ModalSkeleton

const registry = {
  SiweModal,
  CommonModal,
  ErrorModal,
  SuccessModal,
  WaitingModal: dynamic(() => import('compositions/modals/WaitingModal/WaitingModal'), {
    loading,
  }),
  SwitchChainModal: dynamic(() => import('compositions/modals/SwitchChainModal/SwitchChainModal'), {
    loading,
  }),
  DepositModal: dynamic(() => import('compositions/modals/DepositModal/DepositModal'), {
    loading,
  }),
  LootboxModal: dynamic(() => import('compositions/modals/LootboxModal/LootboxModal'), {
    loading,
  }),
}

registerModals(registry)

const GlobalModalsRegistrar: React.FC = () => <ModalsRenderer />

export default GlobalModalsRegistrar
