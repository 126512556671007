'use client'

import ConnectButtonWrapper from 'compositions/ConnectButtonWrapper/ConnectButtonWrapper'
import { useAccount, useDisconnect } from 'wagmi'
import { shortenAddress, toLocaleString } from 'helpers'
import Image from 'next/image'
import userImage from 'public/images/user.png'
import { Message } from '@locmod/intl'
import { Icon } from 'components/ui'
import messages from './messages'
import { useProfileMe } from 'hooks'
import { useAuthContext } from 'contexts'

const Balance = () => {
  const authValue = useAuthContext()
  const { data, isFetching } = useProfileMe()

  if (!authValue.address) {
    return null
  }

  return (
    <div className="mr-6 text-right leading-none mb:hidden">
      <Message className="text-px-12 text-white" value={messages.balance} />
      <div className="flex items-center justify-end">
        <Icon className="mr-1 size-6 flex-none" name="ui/coin" />
        <span className="text-px-16 font-bold text-white">
          {isFetching ? (
            <Icon className="size-4" name="ui/loading" />
          ) : (
            toLocaleString(data?.balance || 0)
          )}
        </span>
      </div>
    </div>
  )
}
const ProfileButton: React.FC = () => {
  const account = useAccount()
  const { disconnect, isPending } = useDisconnect()

  return (
    <ConnectButtonWrapper connectViewProps>
      <div className="inline-flex items-center">
        <Balance />
        <button
          type="button"
          className="flex h-10 items-center gap-1 rounded-3 bg-accent3-light pl-2 pr-3 lg:h-16 lg:gap-2 lg:rounded-5 lg:px-4"
          disabled={isPending}
          onClick={() => disconnect()}
        >
          <Image className="size-6 flex-none lg:size-10" src={userImage} alt="" />
          <span className="text-px-12 font-bold text-black">{shortenAddress(account.address)}</span>
        </button>
      </div>
    </ConnectButtonWrapper>
  )
}

export default ProfileButton
