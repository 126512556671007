export default {
  docs: {
    en: 'Docs',
  },
  discord: {
    en: 'Discord',
  },
  twitter: {
    en: 'X',
  },
  audited: {
    en: 'Audited',
  },
}
