'use client'

import { type ReactNode, useEffect } from 'react'
import { Message } from '@locmod/intl'
import { type ModalComponent, openModal } from '@locmod/modal'
import { useAccount, useDisconnect, useSignMessage } from 'wagmi'
import { SiweMessage } from 'siwe'
import { UserRejectedRequestError } from 'viem'
import { useQuery, useMutation } from '@tanstack/react-query'
import { queryClient, authDataRef } from 'contexts'
import { restApi } from 'helpers'

import { Button, buttonMessages } from 'components/inputs'
import { PlainModal, Icon } from 'components/ui'
import messages from './messages'

type SiweModalProps = {}

const SiweModal: ModalComponent<SiweModalProps> = (props) => {
  const { closeModal } = props

  const { address, chainId } = useAccount()
  const { disconnect, isPending: isDisconnecting } = useDisconnect()
  const { data: siweMessage, isFetching: isFetchingNonce } = useQuery({
    queryKey: ['auth', 'nonce', address],
    queryFn: async () => {
      const { data } = await restApi.get<{ nonce: string }>('/auth/nonce')

      return new SiweMessage({
        domain: window.location.host,
        address,
        statement: 'Sign in with Ethereum to the MoneyPig App.',
        uri: window.location.origin,
        version: '1',
        chainId,
        nonce: String(data.nonce),
      })
    },
    enabled: !!address,
    refetchOnWindowFocus: false,
  })

  const { mutateAsync: verify, isPending: isVerifying } = useMutation({
    mutationFn: async ({ message, signature }: { message: SiweMessage; signature: Hex }) => {
      const verifyRes = await restApi.post<{ token: string; expired_at: string }>('/auth/verify', {
        message: message.prepareMessage(),
        signature,
        address: message.address,
        ref_code: authDataRef.current.refCode,
      })

      const { token, expired_at } = verifyRes.data

      authDataRef.set({ token, address: message.address as Address })

      const isOk = Boolean(token)

      if (isOk) {
        queryClient.refetchQueries({ queryKey: ['profile'] })
      }

      return isOk
    },
  })

  const { signMessageAsync, isPending: isSigning } = useSignMessage()

  const action = async () => {
    let closeWaitingModal: any

    try {
      closeWaitingModal = openModal('WaitingModal', { isSign: true })
      const signature = await signMessageAsync({
        account: siweMessage!.address as Address,
        message: siweMessage!.prepareMessage(),
      })

      closeWaitingModal()

      await verify({ signature, message: siweMessage! })
      closeModal()
    } catch (error) {
      closeWaitingModal?.()
      if (error instanceof UserRejectedRequestError) {
        return
      }

      // openModal('ErrorModal', { error })
    }
  }

  const handleClick = () => {
    action()
  }

  const handleCloseModal = () => {
    disconnect()
    closeModal(true)
  }

  useEffect(() => {
    if (!address) {
      closeModal()
      return
    }
  }, [address])

  return (
    <PlainModal className="flex flex-col text-center" closeModal={handleCloseModal}>
      <Message
        className="text-center text-px-24 font-black leading-150p text-grey-900"
        tag="h2"
        value={messages.title}
        html
      />
      <Icon className="mx-auto mt-6 size-15" name="ui/status-warning" />
      <Message
        className="text-main mt-6 font-medium text-black"
        value={messages.text}
        tag="p"
        html
      />
      <div className="mt-6 text-center">
        <Button
          title={buttonMessages.signMessage}
          size="big"
          styling="second"
          loadingTitle={isFetchingNonce ? buttonMessages.preparing : buttonMessages.processing}
          loading={isDisconnecting || isFetchingNonce || isVerifying || isSigning}
          onClick={handleClick}
        />
      </div>
    </PlainModal>
  )
}

declare global {
  interface ModalsRegistry extends ExtendModalsRegistry<{ SiweModal: typeof SiweModal }> {}
}

export default SiweModal
