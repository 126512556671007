export default {
  title: {
    en: 'Oops...',
  },
  text: {
    en: 'Something went wrong. Please try again later.',
  },
  details: {
    en: 'Details: {errorText}',
  },
}
