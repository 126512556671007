'use client'

import { useEffect } from 'react'
import { Message } from '@locmod/intl'
import type { ModalComponent } from '@locmod/modal'
import { ContractFunctionExecutionError } from 'viem'
import { isUserRejectedRequestError } from 'helpers'

import CommonModal from 'compositions/modals/CommonModal/CommonModal'
import messages from './messages'

type ErrorModalProps = {
  title?: Intl.Message | string
  text?: Intl.Message | string
  error?: any
  withLogger?: boolean
}

const ErrorModal: ModalComponent<ErrorModalProps> = (props) => {
  const { name, title, text = messages.text, error, withLogger = true, closeModal } = props

  let errorText = ''

  const isRejected = isUserRejectedRequestError(error)

  // display contract revert error in dev and preprod (add prod with adding sentry error code)
  if (!isRejected && error instanceof ContractFunctionExecutionError) {
    errorText = error.shortMessage

    if (error.metaMessages?.[0]) {
      errorText += `\n${error.metaMessages?.[0]}`
    }
  }

  useEffect(() => {
    if (isRejected) {
      closeModal()
    }

    if (error && withLogger) {
      console.error(error)
    }
  }, [])

  return (
    <CommonModal
      name={name}
      title={title || messages.title}
      subTitle={text}
      iconName="ui/status-error"
      closeModal={closeModal}
    >
      {Boolean(errorText) && (
        <Message value={{ ...messages.details, values: { errorText } }} tag="p" html />
      )}
    </CommonModal>
  )
}

declare global {
  interface ModalsRegistry extends ExtendModalsRegistry<{ ErrorModal: typeof ErrorModal }> {}
}

export default ErrorModal
